import * as React from "react";

export const Accel = ({className}) => (
  <svg
    width='52'
    height='18'
    viewBox='0 0 189 60'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
  >
    <path
      d='M145.61 49.3C144.378 47.8742 143.44 46.2192 142.85 44.43L142.69 43.98L142.53 43.43L142.43 43.09L142.27 42.46L142.19 42.09C142.11 41.74 142.04 41.37 141.98 41C141.968 40.8656 141.948 40.732 141.92 40.6L141.84 39.95C141.856 39.8071 141.856 39.6629 141.84 39.52L141.79 38.97L169.79 32.22C169.626 30.7703 169.195 29.3634 168.52 28.07C167.482 25.8084 165.793 23.9075 163.67 22.61C161.318 21.1225 158.676 20.1543 155.92 19.77L155.45 19.7C153.788 19.5163 152.112 19.5163 150.45 19.7C146.041 20.1768 141.902 22.0555 138.64 25.06C135.419 27.9904 133.266 31.9099 132.52 36.2V36.37C132.47 36.65 132.43 36.92 132.4 37.23C132.37 37.54 132.4 37.58 132.34 37.78C132.353 37.933 132.353 38.0869 132.34 38.24C132.36 38.5063 132.36 38.7737 132.34 39.04V40.33C132.405 42.6456 132.893 44.9299 133.78 47.07C135.361 50.7379 137.974 53.8674 141.301 56.0777C144.628 58.288 148.526 59.4839 152.52 59.52C155.898 59.5882 159.243 58.8404 162.27 57.34C165.264 55.8613 167.847 53.6664 169.79 50.95L169.31 49.74C167.33 53.52 153.67 58.22 145.61 49.3ZM158.35 32.69L141.7 36.69C141.7 36.19 141.7 35.69 141.77 35.22V35.07C141.762 34.9101 141.762 34.7499 141.77 34.59L141.85 33.94C142.001 32.8996 142.235 31.873 142.55 30.87C143.115 29.0089 143.98 27.2525 145.11 25.67C146.141 24.2115 147.466 22.9852 149 22.07C150.468 21.1923 152.15 20.7357 153.86 20.75C156.247 20.75 157.993 21.4166 159.1 22.75C159.503 23.2146 159.84 23.7329 160.1 24.29C161.21 27.12 161.13 31.83 158.35 32.69Z'
      fill='currentColor'
    />
    <path
      d='M95.64 49.71C93.64 53.53 80 58.22 71.94 49.31C70.6182 47.7697 69.6307 45.9718 69.04 44.03C68.3446 41.8281 68.007 39.5289 68.04 37.22C68.025 35.0643 68.3421 32.9192 68.98 30.86C69.5448 28.999 70.4095 27.2426 71.54 25.66C72.5529 24.2134 73.8533 22.9912 75.36 22.07C76.8284 21.1928 78.5096 20.7362 80.22 20.75C82.6067 20.75 84.3533 21.4167 85.46 22.75C86.6005 24.1917 87.1891 25.993 87.12 27.83C87.1444 28.784 87.0022 29.7349 86.7 30.64L95.2 28.64C92.11 20.64 81.87 19.53 79.98 19.53C63.76 19.27 54.86 34.53 60.1 46.96C61.6831 50.6302 64.2975 53.7621 67.6257 55.9756C70.954 58.1891 74.8531 59.3891 78.85 59.43C82.228 59.4983 85.5727 58.7504 88.6 57.25C91.5946 55.7717 94.1777 53.5767 96.12 50.86L95.64 49.71Z'
      fill='currentColor'
    />
    <path
      d='M132.52 49.71C130.52 53.53 116.88 58.22 108.82 49.31C107.512 47.78 106.535 45.996 105.95 44.07C105.255 41.8681 104.917 39.5689 104.95 37.26C104.935 35.1043 105.252 32.9592 105.89 30.9C106.452 29.0396 107.313 27.2832 108.44 25.7C109.451 24.2406 110.751 23.005 112.26 22.07C113.728 21.1924 115.41 20.7357 117.12 20.75C119.5 20.75 121.247 21.4167 122.36 22.75C123.511 24.1876 124.111 25.9893 124.05 27.83C124.076 28.7848 123.93 29.7365 123.62 30.64L132.07 28.64C129 20.64 118.76 19.54 116.89 19.54C100.67 19.28 91.77 34.54 97.01 46.97C98.5829 50.6496 101.192 53.7923 104.519 56.0152C107.847 58.2382 111.749 59.4455 115.75 59.49C119.135 59.5615 122.487 58.8136 125.52 57.31C128.514 55.8313 131.097 53.6364 133.04 50.92L132.52 49.71Z'
      fill='currentColor'
    />
    <path
      d='M182.05 51.49V0.48999L170.05 3.40999H169.97V4.53999C170.39 4.53999 174.02 5.53999 174.02 8.03999V51.31C174.02 56.77 170.16 57.56 167.55 57.73V58.64H188.52V57.73C185.92 57.56 182.14 56.76 182.05 51.49Z'
      fill='currentColor'
    />
    <path
      d='M37 0.48999L25.12 3.39999H25.03V4.27999C25.45 4.27999 28.98 5.52999 28.03 7.77999C23.52 17.76 8.89003 51.07 8.12003 52.57C5.59003 57.57 2.00003 57.57 0.520027 57.73V58.64L19.12 58.64L19.12 57.71C19.12 57.71 12.06 57.79 12.06 54.1C12.06 51.86 15.71 43.05 16.7 40.64L40.93 34.73C40.99 34.88 47.51 50.67 47.51 54C47.51 57.6 41.72 57.71 41.51 57.71V58.62H65.78V57.71C64.0572 57.7393 62.3637 57.2621 60.9096 56.3377C59.4554 55.4133 58.3047 54.0824 57.6 52.51C56.87 51.07 37 0.48999 37 0.48999ZM40.09 32.69L17.72 38.15C17.84 37.86 30.01 9.33999 30.35 8.61999C30.86 9.80999 40.08 32.69 40.08 32.69H40.09Z'
      fill='currentColor'
    />
  </svg>
);
