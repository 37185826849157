import {StyleExtractor} from "@kubric/utils";
import React from "react";
import {InlineWidget, useCalendlyEventListener} from "react-calendly";

import MMCta from "../commons/MMCTA";
import SanityRichText from "../commons/SanityRichText";
import {GoldenKitty} from "../icons/GoldenKitty";
import {ShopifyReviewsIcon} from "../icons/ShopifyReview";
import {Accel} from "./icons/Accel";
import {Forbes} from "./icons/Forbes";
import {LightSpeed} from "./icons/LightSpeed";
import {Ticket} from "./icons/Ticket";
import * as styles from "./styles.module.scss";

const ScreenState = {
  DEFAULT: "DEFAULT",
  CALENDLY_START: "CALENDLY_START",
  CALENDLY_CONFIRMED: "CALENDLY_CONFIRMED",
  FINAL: "FINAL",
};

const CalendlyConfirmed = "calendly.event_scheduled";

const AboveFooterSection = ({
  data = {},
  hideTicketSectionInDesktop = false,
  hideTicketSection = false,
  openAppListingPage = false,
  hideBackgroundTicket = false,
  plainBackground = false,
  theme = {},
}) => {
  const styler = new StyleExtractor(styles, theme);
  const {
    _rawHeading,
    heading,
    _rawFinalHeading,
    _rawDescription,
    description,
    cta = "Get Early Access",
    ctaUrl,
    placeholder = "Enter Your Email",
  } = data || {};

  const [screenState, setScreenState] = React.useState(ScreenState.DEFAULT);
  const [email, setEmail] = React.useState("");

  const calendlyEventHandler = ({data: {event}}) => {
    if (event === CalendlyConfirmed) {
      setScreenState(ScreenState.FINAL);
    }
  };

  useCalendlyEventListener({
    onDateAndTimeSelected: calendlyEventHandler,
    onEventScheduled: calendlyEventHandler,
    onEventTypeViewed: calendlyEventHandler,
    onProfilePageViewed: calendlyEventHandler,
  });

  return (
    <div
      className={`flex flex-col items-center ${styler.get([
        "lastSectionWrapper",
        hideTicketSectionInDesktop && "transparentBg",
        hideBackgroundTicket && "transparentTicket",
      ])}`}
    >
      <div
        className={styler.get([
          "lastSection",
          (hideTicketSectionInDesktop || hideTicketSection) && "hideInDesktop",
          hideTicketSection && "hideInMobile",
          screenState === ScreenState.DEFAULT ? "blurred" : "calendly",
        ])}
      >
        {heading ? (
          <div className={styler.get("heading")}>
            <h3>{heading}</h3>
          </div>
        ) : (
          <SanityRichText
            blocks={
              screenState === ScreenState.FINAL ? _rawFinalHeading : _rawHeading
            }
            renderContainerOnSingleChild
            className={styler.get("heading")}
          />
        )}
        {description ? (
          <div className={styler.get("description")}>
            <p>{description}</p>
          </div>
        ) : (
          <SanityRichText
            blocks={_rawDescription}
            renderContainerOnSingleChild
            className={styler.get("description")}
          />
        )}

        {screenState === ScreenState.DEFAULT ? (
          <div className={styler.get(["mmTicketContainer", "hideInMobile"])}>
            <Ticket />
          </div>
        ) : (
          <div className={styler.get("calendlyContainer")}>
            <InlineWidget
              url='https://calendly.com/kaus/unlock-3-0'
              prefill={{email}}
            />
          </div>
        )}
      </div>

      {/* Popup cta container */}
      {screenState === ScreenState.DEFAULT && (
        <div
          className={styler.get([
            "popupSection",
            "hideInMobile",
            (hideTicketSectionInDesktop || hideTicketSection) &&
              "hideInDesktop",
          ])}
        >
          {heading ? (
            <div className={styler.get("heading")}>
              <h3>{heading}</h3>
            </div>
          ) : (
            <SanityRichText
              blocks={_rawHeading}
              renderContainerOnSingleChild
              className={styler.get("heading")}
            />
          )}
          {description ? (
            <div className={styler.get("description")}>
              <p>{description}</p>
            </div>
          ) : (
            <SanityRichText
              blocks={_rawDescription}
              renderContainerOnSingleChild
              className={styler.get("description")}
            />
          )}
          <MMCta
            cta={cta}
            id='mm-install-app-salescta'
            theme={{container: styler.get("mmCtaContainer")}}
            v3
            footer={false}
            emailPlaceholder={placeholder}
            href={
              openAppListingPage || !ctaUrl
                ? "https://www.shopify.com/admin/oauth/authorize?client_id=70a074b87b07f9468ceafd018c360396&scope=write_products%2Cwrite_script_tags%2Cwrite_themes%2Cread_discounts%2Cread_orders%2Cread_inventory%2Cread_price_rules&redirect_uri=https%3A%2F%2Fapp.getmodemagic.com%2Finstall%2Fcallback"
                : ctaUrl
            }
            customClickHandler={
              !openAppListingPage &&
              ((value) => {
                setEmail(value);
                setScreenState(ScreenState.CALENDLY_START);
              })
            }
          />
        </div>
      )}
      <BottomFeaturedSection
        theme={theme}
        hideTicketSection={hideTicketSection}
        plainBackground={plainBackground}
      />
      <div
        className={styler.get(["footerBuffer", plainBackground && "plainBg"])}
      />
    </div>
  );
};

export const BottomFeaturedSection = ({
  theme = {},
  hideTicketSection = false,
  plainBackground,
}) => {
  const styler = new StyleExtractor(styles, theme);
  return (
    <div
      className={styler.get([
        "bottomSectionWrapper",
        plainBackground && "plainBg",
      ])}
    >
      <div className={styler.get(["bottomSection", hideTicketSection && "mb"])}>
        <div className={styler.get("left")}>
          <span>Backed by</span>
          <div className={styler.get("wrapper")}>
            <div className={styler.get("div")}>
              <LightSpeed /> <span>Lightspeed Venture Partners</span>
            </div>
            <div className={styler.get("divider")} />
            <div className={styler.get("div")}>
              <Accel /> <span>Accel Partners</span>
            </div>
            <div className={styler.get("divider")} />
            <div className={styler.get("div")}>
              <img
                src='https://media.kubric.io/api/assetlib/c7deeed2-e820-4f43-a27d-5d4851b41be5.png'
                alt='Ideaspring Capital'
              />
              <span>Ideaspring Capital</span>
            </div>
          </div>
        </div>
        <div className={styler.get("divider")} />
        <div className={styler.get("right")}>
          <span>Featured on</span>
          <div className={styler.get("wrapper")}>
            <div className={styler.get(["div", "noCursor", "hideInMobile"])}>
              <ShopifyReviewsIcon />
            </div>
            <div className={styler.get(["divider", "hideInMobile"])} />
            <div
              className={styler.get(["div", "kitty", "cursor", "hideInMobile"])}
              onClick={() => {
                let newWindow = window.open(
                  "https://www.producthunt.com/stories/announcing-the-golden-kitty-awards-2021-winners",
                  "_blank"
                );
                newWindow.opener = null;
              }}
            >
              <GoldenKitty />
            </div>
            <div className={styler.get(["divider", "hideInMobile"])} />
            <div
              className={styler.get(["div", "shopCommAwardsDiv", "cursor"])}
              onClick={() => {
                let newWindow = window.open(
                  "https://www.shopify.in/partners/blog/shopify-commerce-awards-2020-winners",
                  "_blank"
                );
                newWindow.opener = null;
              }}
            >
              <>
                <span>
                  <b>Shopify</b> 20
                </span>
                <span>
                  <b>Commerce</b>
                </span>
                <span>
                  <b>Awards</b> 20
                </span>
              </>
            </div>
            <div className={styler.get("divider")} />
            <div
              className={styler.get(["div", "cursor", "forbes"])}
              onClick={() => {
                let newWindow = window.open(
                  "https://www.linkedin.com/posts/forbes-india_forbes-india-kausambi-manjita-ceo-of-mason-activity-6906527777130905600-mjyU?utm_source=linkedin_share&utm_medium=ios_app",
                  "_blank"
                );
                newWindow.opener = null;
              }}
            >
              <Forbes /> <span>Forbes Tech</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboveFooterSection;
